export function useTelegramLoginErrors() {
  const { open } = useToast()
  const { t } = useI18n()

  function showTelegramToastErrorByCode(errorCode?: string) {
    if (errorCode === 'AUTH_FORBIDDEN') {
      open({
        label: t('authorization.errorMessages.selfExclusionError'),
        type: 'negative',
      })
      return
    }
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
  }

  return {
    showTelegramToastErrorByCode,
  }
}
